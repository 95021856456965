body{
    width:100%;
    margin:auto;
}
/*************header CSS*****************/
.button{
    background: none;
    color:#0080C0;
    cursor:pointer;
    border:1px solid #0080c0;
    
    padding:5px;
}
.button:hover{
    background: #0080c0;
    color:#fff;
}

/************* Login Form CSS *******************/
#login-form{
    width:30%;
    margin:150px auto;
    box-shadow:0 0 10px rgba(0,0,0,.3);
    min-width: 120px;
    padding-bottom: 5px;
}
#login-head{
    background: #283c51;
    padding: 1px 0 1px 0;
    color:#fff;
    margin-bottom: 15px;
}
#login-head h3{
    text-align: center;
}
.login-row{
    width:90%;
    text-align: center;
    padding: 8px 0;
}
.login-row input[type="text"],.login-row input[type="password"]{
    width:55%;
    border: 1px solid #0080C0;
    border-radius:4px;
    box-shadow:0 0 10px rgba(0,0,0,.3);
    padding: 2px 0;
    font-family: Times New Roman;
    min-width:100px;
}
.login-button{
    width:55%;
    padding: 5px 0;
    background: none;
    color:#0080C0;
    border:1px solid #0080c0;
    border-radius:4px;
    cursor:pointer;
    min-width: 100px;
}
.login-button:hover{
    background: #0080c0;
    color:#fff;
}