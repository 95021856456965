@import url('https://cdn.syncfusion.com/ej2/material.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.material-icons {
    font-size: 22px;
    vertical-align: middle;
    margin-right:15px;
  }
.content-header{
  margin-top: -5px;
  margin-bottom: 30px;
}
.content{
  background-color: #fff;
  /* color:darkmagenta; */
  color:#FF383F;
  font-weight: 600;
  margin: 0px;
  margin-top: -40px;
}
.card {
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
transition: 0.3s;
width:250px; 
height: 350px; 
margin: 20px; 
border-top:5px coral solid; 
border-radius:5px;
}
.info {
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
transition: 0.3s;
min-height: 400px;
margin: 10px; 
border-top:5px #FF383F solid; 
border-radius:5px;
padding-bottom: 10px;
}

.card:hover {
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.linkstyle{
  background-color: #9A1750;
  font-family:Arial, Helvetica, sans-serif;
  font-size:14px;
  font-weight: 600;
  color:#fff;
  padding: 10px 0 10px 2px;
  display:block;
  margin: 2px 0 2px 0;
  border:2px #999 solid;
  border-radius: 5px;
  cursor: pointer;
}
.react-tabs__tab{
  color:#989898;
}
.react-tabs__tab--selected{
  color: #FF383F;
}
.heading{
  color:blueviolet !important;
  text-align: center;
  text-transform: capitalize;
  font-family: 'Times New Roman', Times, serif;
  font-size: 30px;
  text-decoration: underline;
}
.sub-heading{
  text-align: center;
  text-transform: capitalize;
  font-family: 'Times New Roman', Times, serif;
  font-size: 22px;
  text-decoration: underline;
  margin-bottom: 10px;
}
.preds{
  font-family:'Times New Roman', Times, serif;
  font-stretch: semi-expanded;
  font-size: 18px;
  font-weight: 300;
  color:#000;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  text-align: justify;
}
.leftmenu{
  background-color: #ddd;
  padding-top:20px;
  padding-bottom:20px;
  min-height: 75vh;
  max-height: 75vh;
  overflow: scroll;
  border-radius: 20px;
}
.contents{
  padding-bottom:20px;
  min-height: 77vh;
  max-height: 77vh;
  overflow: scroll;
}
.modal {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
  background-color: white;
  box-shadow: 5px 5px 5px #999;
  position: fixed;
  top: 25%;
  left: 30%;
  width: 40%;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #999;
}

.close {
  color: #fff;
  float: right;
  font-size: 22px;
  font-weight: 700;
  opacity: 90%;
}

.popup-heading{
  background-color: #9A1750;
  color:#fff;
  padding:10px;
  border-radius: 5px;
}
.skin-blue .navbar{
  background-color:#A9A9A9;
}
.skin-blue .navbar .nav a{
  /* color:#FF3B3F; */
  color:#fff;
}
.skin-blue .logo{
  background-color: #FF383F;
}
.skin-blue .logo:hover{
  background-color: #FF383F;
}
.predhead{
  font-family: cursive;
  font-size: 22px;
  font-weight: 550;
  text-decoration: underline;
  color:#9A1750;
}
.bn{
  color:#000;
}
.planebox{
  background-color: #eee;
  border:2px #9A1750 solid;
  border-radius:20px;
  width:100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px 0px 10px 0px;
}
.planehead{
  background-color: #9A1750;
  color:#fff;
  font-size: 22px;
  padding-left: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 0px;
}
.planesubhead{
  color:#9A1750;
  font-size: 18px;
  margin-top: -20px;
  margin-bottom: -20px;
}
.planecontent{
  padding: 15px;
  text-align: justify;
  margin-bottom: -10px;
}
.navbar-nav > .user-menu > .dropdown-menu:after{
  left:10px !important;
}
.numhead{
  display:block;
  padding:10px;
  border: 2px #ccc dashed;
  border-radius: 5px;
  margin: 10px;
}
.planetable{
  border:2px;
}
.datedd{
  width:60px;

}
.onlyprint{
  display:none;
}
.checkprint{
  display: block;
  padding: 10px;
  border: 2px #ccc solid;
  margin: 10px;
  width:98%;
}
.printheadcheck{
  width: 100%;
  padding: 10px;
  border:1px #aaa solid;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
  background-color: fuchsia;
  color:#000;
}
.printheadcheckprime{
  width: 100%;
  padding: 10px;
  border:1px #aaa solid;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
  background-color:#FF383F;
  color:#000;
}
@media print {
  .onlyprint{
    display:block;
  }
  .onlyscreen{
    display: none;
  }
}
@media print {
  @page {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  .pagebreak{ page-break-before: always; padding-top: 40px; } /* page-break-after works, as well */
  .info{
      border:0px;
  }
  .glyphicon{
      display:none;
  }
  h1 h2 h3 h4 h5 h6{
color: black;
text-transform:capitalize;
font-family: 'Times New Roman', Times, serif;
font-size:large;
  }
  .heading{
      color: black;
      text-align: center;
      text-transform: capitalize;
      font-family: 'Times New Roman', Times, serif;
      font-size: 30px;
      text-decoration: underline;
    }
    p, .preds{
        color: black;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: large;
        page-break-inside: avoid;
    }
     .numhead{
        border:1px #ccc solid;
        margin:0px;
        font-size: 14px;
        /* color:crimson !important; */
    }
  .printdiv{
      /* border:4px #ccc dashed; */
       display:block;
      padding:30px;
      width:100%;
      /* color:crimson !important; */
  }
  div.divHeader {
      display: block;
      position: fixed;
      top: 0;
    }
}
