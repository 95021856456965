/* @page {
    size: A4;
    margin: 11mm 17mm 17mm 17mm;
  } */
  div.divHeader {
      width: 100vw;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      background-color: #ddd;
      border-bottom: 1px #ccc solid;
      display:none;
  }
  div.divFooter {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    background-color: #ddd;
    border-top: 1px #ccc solid;
    display:none;
  }
  /* div.divData{
       padding: 100px 10px 250px 10px; 
      margin-bottom: 250px;
  } */
  .printdiv{
      display: none;
}
.checkprint input{
    margin-right: 10px;
}
/* @page {
    size: A4;
    margin: 5mm 17mm 17mm 5mm;
  } */
/* .pagebreak{ page-break-before: always; padding-top: 40px; } page-break-after works, as well */
@media print {
    /* @page {
        margin-top: 30px;
        margin-bottom: 30px;
      } */
    .pagebreak{ page-break-before: always; padding-top: 40px; } /* page-break-after works, as well */
    .info{
        border:0px;
    }
    .glyphicon{
        display:none;
    }
    h1 h2 h3 h4 h5 h6{
color: black;
text-transform:capitalize;
font-family: 'Times New Roman', Times, serif;
font-size:large;
    }
    .heading{
        color: black;
        text-align: center;
        text-transform: capitalize;
        font-family: 'Times New Roman', Times, serif;
        font-size: 30px;
        text-decoration: underline;
      }
      p, .preds{
          color: black;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
          font-size: large;
          /* page-break-inside: avoid; */
      }
       .numhead{
          border:1px #ccc solid;
          margin:0px;
          font-size: 14px;
          /* color:crimson !important; */
      }
    .printdiv{
        /* border:4px #ccc dashed; */
         display:block;
        padding:30px;
        width:100%;
        /* color:crimson !important; */
    }
    div.divHeader {
        display: block;
        position: fixed;
        top: 0;
      }
    div.divFooter {
        display: block;
        position: fixed;
        bottom: 0;
      }
}
